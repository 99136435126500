* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Arial', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #419BE0;
}

p {
  margin: 0;
}

@font-face {
  font-family: 'Arial';
  src: local('Arial'), url('../public/arial.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
